(function() {
	/**
	 * @exports DKA.controllers.home
	 * @requires HBS
	 */
	let module = {};

	module.init = () => {
		// console.log('products.js initialized.')
	};

	module.productLanding = () => {

		////////////////////////////////////////////////////////////////////////////////
		// needed to make the menu hide after user clicks on an anchor link from mega menu
		// add event listener to product collection items
		var collectionLinks = document.getElementsByClassName("js-collection-menu");

		var handleCollectionClick = function() {	

			// if this isn't the mobile nav 		
			if ( (mobileHeader.offsetParent === null) ) {

			    var navElement = document.querySelector('.js-menu-products');
			    var navChild = navElement.querySelector('.sub-menu');
				
			    navChild.style.height = '0';					 

			    // now let's listen for another hover to remove our 0 height
			    navElement.firstChild.addEventListener('mouseover', e => {
					navChild.removeAttribute('style');
				});
		
			// mobile nav deactivate menu on collection click 	
			} else {
				document.querySelector('body').classList.remove('state-fixed');
				document.getElementById('headerWrapper').classList.remove('header__wrapper--active');
			}			
		}
 		 		
		for (var i = 0; i < collectionLinks.length; i++) {
			collectionLinks[i].addEventListener('click', handleCollectionClick, false);
			console.log(collectionLinks[i]);
		}
		

	};
 
 	module.productDetails = () => {

		// setup our finishes swiper
		var finishSwiper = new Swiper('.swiper-container-finishes', {
	        loop: false,
			navigation: {
				nextEl: ('.swiper-button-next-finish'),
				prevEl: ('.swiper-button-prev-finish'),
			}
	    });

		// setup our finishes modal for our finishes swiper
		jQuery('#finishModal').on('shown.bs.modal', function(e) {			
			finishSwiper.update();
			var $invoker = jQuery(e.relatedTarget);
			finishSwiper.slideTo($invoker.data('slider'),false,false);
			finishSwiper.update();
		});

		//////////////////////////////////////////////////////////////////
		//series section show more - less clicks
		let moreLinks = document.getElementsByClassName("js-toggle-more"); 

		//loop through our elements 
		for (var i = 0; i < moreLinks.length; i++) { 
		  	moreLinks[i].addEventListener('click', moreClick); 		  	
		}

		function moreClick(){
			this.parentNode.classList.toggle('show');
		}
 	};

	var docReady = function docReady(callback) {
	// in case the document is already rendered
		if (document.readyState !== 'loading') {
			callback();
		} else if (document.addEventListener) {
			// modern browsers
			document.addEventListener('DOMContentLoaded', callback);
		} else {
			// IE <= 8
			document.attachEvent('onreadystatechange', function () {
				if (document.readyState === 'complete') callback();
			});
		}
	};

	docReady(function () {
		// module.init(); 
		HBS.initPage();
	});

	HBS.namespace('DKA.controllers.products', module);
}());